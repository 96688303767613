import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./css/gogoit.scss";
import "./css/gogoit_m.scss";
import "./css/notosans.css";


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
    ,
    document.getElementById('root')
);
