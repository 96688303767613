import React from 'react';
import { withRouter } from "react-router-dom";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            <div className="download-container">
                <div>
                   <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        <div className="logo-content mobile">
                            <div style={{ textAlign: 'center' }}>
                                <img src={require('../img/common/logo_circle.png').default}
                                     style={{ width: 170, paddingTop: 50 }} alt=""/>
                            </div>
                            <div className="text">
                                고고잇 다운로드
                            </div>
                        </div>
                        <div className="content-box">
                            <div>
                                가맹점
                            </div>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/GogoitOrderRelationAppSetup.msi">
                                <div className="btn" onClick={() => { }}>
                                    배민연동 프로그램
                                </div>
                            </a>
                            <a href="https://fr.gogoit.io/" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    가맹점 웹(김포)
                                </div>
                            </a>
                            <a href="https://gyfr.gogoit.io/" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    가맹점 웹(고양/파주)
                                </div>
                            </a>
                        </div>

                        <div className="content-box" style={{ marginTop: 30 }}>
                            <div>
                                라이더
                            </div>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/gogoit_rider-gp.apk">
                                <div className="btn" onClick={() => { }}>
                                    안드로이드 앱(김포)
                                </div>
                            </a>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/gogoit_rider-gy.apk">
                                <div className="btn" onClick={() => { }}>
                                    안드로이드 앱(고양/파주)
                                </div>
                            </a>
                        </div>
                        <div className="content-box" style={{ marginTop: 30 }}>
                            <div>
                                가맹점 퀵 메뉴 (크롬, 파이어폭스 전용)
                            </div>
                            <div className="content-important">
                                * 가맹점 웹 프로그램을 사용하는 브라우저를<br />기본 브라우저로 설정해주세요.
                            </div>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/GpGogoitLinkAppSetup.msi" target="_blank">
                                <div className="btn" onClick={() => { }}>
                                    가맹점 퀵 메뉴 설치 프로그램(김포)
                                </div>
                            </a>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/GyGogoitLinkAppSetup.msi" target="_blank">
                                <div className="btn" onClick={() => { }}>
                                    가맹점 퀵 메뉴 설치 프로그램(고양 / 파주)
                                </div>
                            </a>
                        </div>

                        {/*<div className="content-sub-box">
                            <div>
                                가맹점 퀵 메뉴 (크롬, 파이어폭스 전용)
                            </div>
                            <div className="content-important">
                                * 가맹점 웹 프로그램을 사용하는 브라우저를<br />기본 브라우저로 설정해주세요.
                            </div>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/GpNnboxLinkAppSetup.msi" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    가맹점 퀵 메뉴 설치 프로그램
                                </div>
                            </a>
                        </div>*/}

                        <div className="content-sub-box">
                            <div>
                                설치 프로그램
                            </div>
                            <a href="https://ftp.mozilla.org/pub/firefox/releases/52.9.0esr/win32/en-US/Firefox%20Setup%2052.9.0esr.exe" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    파이어폭스 (XP 전용)
                                </div>
                            </a>
                            <a href="https://www.microsoft.com/ko-kr/download/confirmation.aspx?id=17718" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    .NET Framework 4
                                </div>
                            </a>
                            {/*<a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/CidRelationAppSetup.msi" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    CID 연동 프로그램
                                </div>
                            </a>*/}
                        </div>

                        <div className="content-sub-box">
                            <div>
                                배달 플랫폼 프로그램 설치
                            </div>
                            <a href="https://ceo.baemin.com/guide/G77001" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    배달의민족 설치 링크
                                </div>
                            </a>
                            <a href="https://ceo.baemin.com/guide/G21008" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    배달의민족 설치 링크(XP전용)
                                </div>
                            </a>
                            <a href="https://owner.yogiyo.co.kr/owner/join/order_terminal/" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    요기요 설치 링크
                                </div>
                            </a>
                        </div>

                        <div className="content-sub-box">
                            <div>
                                윈도우7 보안패치(크롬 인증서 오류)
                            </div>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/Windows6.1-KB2813430-x64.msu" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    64Bit OS
                                </div>
                            </a>
                            <a href="https://nnbucketpublic.s3.ap-northeast-2.amazonaws.com/inst/Windows6.1-KB2813430-x86.msu" target="_blank" rel="noreferrer">
                                <div className="btn" onClick={() => { }}>
                                    32Bit OS
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="logo-content desk">
                        <div className="text">
                            고고잇 다운로드
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <img src={require('../img/common/download_logo.png').default}
                                 style={{ width: 170, paddingTop: 50 }} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Main);
